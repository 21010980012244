<template>
  <Page color="info" title="Räume">
    <template slot="help">
      <Markdown :value="helpText" />
    </template>
    <template slot="extension">
      <v-tabs
        background-color="info darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab :to="{ name: 'RoomReservation' }">Reservation</v-tab>
        <v-tab :to="{ name: 'RoomOccupation' }" v-if="$_hasRole('staff')"
          >Belegung</v-tab
        >
      </v-tabs>
    </template>
    <router-view />
  </Page>
</template>

<script>
const helpText = `
Im Tab «RESERVATION» können **Gruppenarbeitsräume** für den Unterricht reserviert werden.
Unter «BELEGUNG» kann die aktuelle Belegung von sämtlichen Unterrichtszimmern eingesehen werden.

- Für Reservationen von Unterrichtszimmern **während der Unterrichtszeiten** und für die Reservation von **Spezialräumen** (z.B. Aula, 125, Mediothek) wendet ihr euch an Jacqueline Weber oder Roland Zürcher (per E-Mail).
- Reservationen **ausserhalb der Unterrichtszeiten** beantragt ihr jeweils bis spätestens Donnerstagmittag der Vorwoche bei Jacqueline Weber oder Roland Zürcher. Für kurzfristigere Reservationen sucht ihr bitte das direkte Gespräch mit Roland Zürcher.
`;

export default {
  name: "Rooms",
  data() {
    return {
      helpText,
    };
  },
};
</script>
